import React from 'react';


import './css/Main6.css';
import '../App.css';

import { Slide } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

import { Main6Carousel } from "./Element/Main6Carousel";

import korea from "../asset/testimoni/korea.jpeg";
import bg from "../asset/testimoni/bg.jpg";
import bgfoot from "../asset/testimoni/bgfoot.png";

export function Main6() {

  const slideFadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
  `

  return (
    <>
      {/* TESTIMONY SECTION */}


      {/* MAIN CONTAINER */}
      <div className={`
      relative
      min-h-max w-full bg-white
      flex flex-col justify-center
      xl:mx-auto xl:m-
      `} id="Testimoni"
      >

        {/* Background Element */}
        <div className={`
        absolute h-full w-full
        bg-cover inset-0 bg-bottom
        `} style={{ backgroundImage: `url(${bg})` }}>
        </div>

        {/* Background Footer */}
        <div className={`
        absolute w-[100%] h-[50%]
        bg-cover bottom-[-2px]
        `} style={{ backgroundImage: `url(${bgfoot})` }}>
        </div>

        <Slide keyframes={slideFadeIn}>

          {/* Gallery 1 */}
          <div className={`
        flex flex-col justify-center relative
        w-[100%]
        rounded-4xl
        min-h-max
        mb-[10rem]
        `}>

            <div className={`
          flex flex-col
          items-center justify-center
          w-[80%] sm:w-[70%] md:w-[60%] lg:w-[65%]
          mx-auto
          `}>

              {/* Header */}
              <div className={`
              mt-20
              bg-[#0227b8] text-white text-2xl
              flex items-center justify-center
              w-full text-center h-14
              rounded-t-4xl
              `} style={{ fontFamily: `Obibok` }}>
                TESTIMONI
              </div>

              {/* Content (Video/Image) */}
              <div className="flex w-full h-auto">
                <img src={korea} className={`
                w-full h-auto aspect-video
                object-cover object-bottom
                rounded-b-4xl
                `} />
              </div>

            </div>

          </div>



          <div className="z-30 h-max overflow-visible">
            {/* <Main6Carousel /> */}

          </div>

        </Slide>

      </div>
    </>
  );
}