import React, { Suspense } from "react";

import { Navpanel } from "./components/Navpanel";
import { Main6 } from "./components/Main6";
import { Footer2 } from "./components/Footer2";

import './App.css';


function App() {

  const Main1 = React.lazy(() => import("./components/Main1").then(module => ({ default: module.Main1 })));
  const Main2 = React.lazy(() => import("./components/Main2").then(module => ({ default: module.Main2 })));
  const Main25 = React.lazy(() => import("./components/Main25").then(module => ({ default: module.Main25 })));
  const Main3 = React.lazy(() => import("./components/Main3").then(module => ({ default: module.Main3 })));
  const Main4 = React.lazy(() => import("./components/Main4").then(module => ({ default: module.Main4 })));
  const Main5 = React.lazy(() => import("./components/Main5").then(module => ({ default: module.Main5 })));

  return (
    <>
      <Navpanel />
      <Suspense fallback={<div>Loading...</div>}>
        <Main1 />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Main2 />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Main25 />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Main3 />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Main4 />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Main5 />
      </Suspense>
      <Main6 />
      <Footer2 />
    </>
  );
}

export default App;