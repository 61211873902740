import React from "react";

import logofull from '../asset/footer/logofull.png';
import email from "../asset/footer/email.png";
import ig from "../asset/footer/ig.png";
import phone from "../asset/footer/phone.png";
import tiktok from "../asset/footer/tiktok.png";
import youtube from "../asset/footer/youtube.png";
import whatsapp from "../asset/footer/whatsapp.png";

export function Footer2() {

  return (

    <>

      {/* Floating Banner */}
      <div className={`
        flex-col absolute items-center text-center
        bg-white rounded-full
        right-1/2 translate-x-[50%] translate-y-[-50%]
        xl:px-52 lg:px-40 md:px-28 sm:px-10 px-8
        py-3 
        md:text-base text-sm
        min-w-max shadow-black drop-shadow-lg
        scale-y-[-1]
        `}
        style={{ fontFamily: 'Gotham' }}>
        <div className="scale-y-[-1]">
          <p className="">Bermimpi untuk Kuliah di Luar Negeri?</p>
          <p className="">Kami Memberikan Sayap pada Impian Anda!</p>
        </div>
      </div>


      {/* ENTIRE CONTAINER */}
      <div>

        <footer class="text-white body-font" style={{ backgroundColor: `#0227b8` }}>

          <div class={`
          container 
          py-24 
          flex
          mx-auto
          `}>

            {/*  */}
            <div className={`
            flex flex-wrap 
            md:text-left text-center -mb-10 
            justify-between
            `}>

              {/* 1. LOGO */}

              <div className={`
              flex flex-col 
              lg:w-1/4 md:w-1/2 w-full 
              h-auto
              px-4`}>
                <div className="flex h-full w-full justify-center">
                  <img src={logofull} className="w-full sm:w-[70%] md:w-full h-auto md:h-max pb-10" />
                </div>

              </div>


              {/* 2. PT Annyeong */}

              <div class="flex flex-col 2xl:w-[23%] lg:w-[25%] md:w-1/2 w-full px-4">
                <div class="title-font font-medium tracking-tight text-sm mb-3" style={{ fontFamily: `Obibok` }}>
                  <div>PT ANNYEONG EDU INDONESIA</div>
                  <div>ANNYEONG ACADEMY</div>
                </div>
                <nav class={`
                flex mx-auto
                md:w-full w-[80%] 
                list-none mb-10`} style={{ fontFamily: `Proxima Nova` }}>
                  <li>
                    <a class="">
                      Graha Multi Building (Lt Mezannie, Unit 207),
                      Jl Panjang No.55, Kebon Jeruk, Jakarta Barat, DKI Jakarta 11530
                    </a>
                  </li>
                </nav>

              </div>

              {/* 3. Hubungi Kami */}
              <div class="flex flex-col 2xl:w-[19%] xl:w-[22%]  md:w-1/2 w-full px-4">
                <h2 class="title-font font-medium tracking-widest text-sm mb-3" style={{ fontFamily: `Obibok` }}>HUBUNGI KAMI</h2>
                <nav class="list-none mb-10 hover:cursor-pointer" style={{ fontFamily: `Proxima Nova` }}>
                  <li>
                    <a class="hover:text-gray-200">
                      <img src={phone} className="inline-block w-4 h-auto mr-2" />
                      +62 21 536-0041
                    </a>
                  </li>
                  <li>
                    <a class="hover:text-gray-200">
                      <img src={whatsapp} className="inline-block w-4 h-auto mr-2" />
                      +62 813 9000 2141
                    </a>
                  </li>
                  <li>
                    <a class="hover:text-gray-200">
                      <img src={email} className="inline-block w-4 h-auto mr-2" />
                      admin@annyeongedu.com</a>
                  </li>
                </nav>
              </div>

              {/* 4. Media Sosial */}
              <div class="flex flex-col 2xl:w-[16.5%] xl:w-[19%] md:w-1/2 w-full px-4">
                <h2 class="title-font font-medium  tracking-widest text-sm mb-3" style={{ fontFamily: `Obibok` }}>MEDIA SOSIAL</h2>
                <nav class="list-none mb-10 hover:cursor-pointer" style={{ fontFamily: `Proxima Nova` }}>
                  <li>
                    <a class="hover:text-gray-200" href="https://instagram.com/annyeongacademy?igshid=MzRlODBiNWFlZA==">
                      <img src={ig} className="inline-block w-4 h-auto mr-2" />
                      @annyeongacademy
                    </a>
                  </li>
                  <li>
                    <a class="hover:text-gray-200" href="https://www.tiktok.com/@annyeongacademy?_t=8g57GElGMEc&_r=1">
                      <img src={tiktok} className="inline-block w-4 h-auto mr-2" />
                      @annyeongacademy
                    </a>
                  </li>
                  <li>
                    <a class="hover:text-gray-200">
                      <img src={youtube} className="inline-block w-4 h-auto mr-2" />
                      YouTube
                    </a>
                  </li>
                </nav>
              </div>

            </div>

          </div>

        </footer>

      </div>


    </>

  )

}