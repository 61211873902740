import React, { useState, useEffect } from "react";
import './fonts.css';
import '../App.css';

import logofull from '../asset/logofull.png';
import ig from '../asset/navbar/ig.png';
import tiktok from '../asset/navbar/tiktok.png';
import wa from '../asset/navbar/wa.png';
import yt from '../asset/navbar/yt.png';


export function Navpanel() {

  const [navbarHidden, setNavbarHidden] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleClick = (e, targetId) => {
    e.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop - 62, // Adjust the 60px offset as needed
        behavior: "smooth"
      });
    }
  };

  const handleClickXs = (e, targetId) => {
    e.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop - 55, // Adjust the 55px offset as needed
        behavior: "smooth"
      });
      setMenuOpen(false);  // Close the dropdown
    }
  };

  useEffect(() => {

    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop;

      if (scrollTop > lastScrollTop) {
        setNavbarHidden(true);
      } else {
        setNavbarHidden(false);
      }

      setLastScrollTop(scrollTop);
    };

    if (menuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop, menuOpen]);

  return (
    <>

      {/* transition-transform duration-300 ${navbarHidden ? '-translate-y-full' : ''} */}

      {/* Navbar Core Container */}
      <div className={`
        flex flex-col z-30
        bg-opacity-80 backdrop-blur-sm 
        fixed top-0 left-0 bg-white shadow-lg h-auto w-full p-3 
        `}
        style={{ fontFamily: 'Gotham' }}>

        {/* Container for Logo + Links + Button */}
        <div className={`
          flex items-center justify-between min-w-full
          `}>

          {/* LOGO FUKURO */}
          <div className="flex text-black mx-2 lg:mx-6">
            <img src={logofull} alt="fukuro" className={`
            h-8 w-auto 
            lg:h-9 xl:h-10
            `} />
          </div>

          {/* Links + Button */}
          <div className={`
          space-x-7 mx-5 
          text-sm text-black font-bold
          tracking-tight
          hidden xl:block
          `}>

            <a href="#Beranda" className="hover:text-[#0227b8]">
              BERANDA
            </a>
            <a href="#Tentang2" className="hover:text-[#0227b8]"
            >
              TENTANG KAMI
            </a>
            <a href="#KepoinYuk" className="hover:text-[#0227b8]"
              onClick={e => handleClick(e, 'KepoinYuk')}>
              KEPOIN YUK
            </a>
            <a href="#Program" className="hover:text-[#0227b8]"
              onClick={e => handleClick(e, 'Program')}>
              PROGRAM
            </a>
            <a href="#MitraKami" className="hover:text-[#0227b8]"
              onClick={e => handleClick(e, 'MitraKami')}>
              MITRA
            </a>
            <a href="#Testimoni" className="hover:text-[#0227b8]"
              onClick={e => handleClick(e, 'Testimoni')}>
              TESTIMONI
            </a>
            {/* <a href="#" className={`
              px-5 py-2 text-md text-white font-semibold bg-blue-800 hover:bg-blue-600 rounded-full
              `}>
              HUBUNGI KAMI</a> */}
            <a className={`
            px-5 py-2.5
            text-md text-white font-semibold 
            bg-[#0227b8] hover:bg-[#f40010] rounded-full`}
              href="https://wa.me/message/FDP5HUUJMOWCK1"
            >
              HUBUNGI KAMI
            </a>

          </div>

          {/* !!! MUST Apply Animation !!! */}
          <div className="flex flex-wrap xl:hidden z-[60]">
            <button className="items-center xl:hidden block h-6 w-6" onClick={() => setMenuOpen(!menuOpen)}>
              <div className="flex flex-col space-y-1">
                <div className="w-6 h-0.5 rounded-full bg-black"></div>
                <div className="w-6 h-0.5 rounded-full bg-black"></div>
                <div className="w-6 h-0.5 rounded-full bg-black"></div>
              </div>
            </button>
          </div>

        </div>

        {/* Mobile Dropdown */}
        <div className={`
        z-50
        fixed inset-0 flex justify-center
        space-y-2 pt-[10rem]
        transition-all duration-300 ease-in-out
        ${menuOpen ? 'opacity-100 transition pointer-events-auto' : 'opacity-0 transition pointer-events-none'}`}>

          <div className={`
          bg-white bg-opacity-40 backdrop-blur-xl
          fixed inset-0  // Added positioning classes here
          transition-all duration-300 ease-in-out
          `}></div>

          <div className={`
          flex flex-col absolute 
          items-center
          space-y-7
          text-2xl
          transition-all duration-300 ease-in-out
          `}>
            <a href="#Beranda" 
            className={`font-bold relative overflow-hidden group`}
            onClick={e => handleClickXs(e, 'Beranda')}>
              Beranda
              <span className="
              absolute inset-x-0 bottom-0 bg-[#0227b8] transform
              transition-all ease-in-out duration-500
              h-[0.15rem] w-full scale-x-0
              group-hover:scale-x-100"></span>
            </a>
            <a href="#TentangKami" 
            className={`font-bold relative overflow-hidden group`}
            onClick={e => handleClickXs(e, 'Tentang2')}>
              Tentang Kami
              <span className="
              absolute inset-x-0 bottom-0 bg-[#0227b8] transform
              transition-all ease-in-out duration-500
              h-[0.15rem] w-full scale-x-0
              group-hover:scale-x-100"></span>
            </a>
            <a href="#KepoinYuk" 
            className={`font-bold relative overflow-hidden group`}
            onClick={e => handleClickXs(e, 'KepoinYuk')}>
              Kepoin Yuk
              <span className="
              absolute inset-x-0 bottom-0 bg-[#0227b8] transform
              transition-all ease-in-out duration-500
              h-[0.15rem] w-full scale-x-0
              group-hover:scale-x-100"></span>
            </a>
            <a href="#Program" 
            className={`font-bold relative overflow-hidden group`}
            onClick={e => handleClickXs(e, 'Program')}>
              Program
              <span className="
              absolute inset-x-0 bottom-0 bg-[#0227b8] transform
              transition-all ease-in-out duration-500
              h-[0.15rem] w-full scale-x-0
              group-hover:scale-x-100"></span>
            </a>
            <a href="#MitraKami" 
            className={`font-bold relative overflow-hidden group`}
            onClick={e => handleClickXs(e, 'MitraKami')}>
              Mitra
              <span className="
              absolute inset-x-0 bottom-0 bg-[#0227b8] transform
              transition-all ease-in-out duration-500
              h-[0.15rem] w-full scale-x-0
              group-hover:scale-x-100"></span>
            </a>
            <a href="#Testimoni" 
            className={`font-bold relative overflow-hidden group`}
            onClick={e => handleClickXs(e, 'Testimoni')}>
              Testimoni
              <span className="
              absolute inset-x-0 bottom-0 bg-[#0227b8] transform
              transition-all ease-in-out duration-500
              h-[0.15rem] w-full scale-x-0
              group-hover:scale-x-100"></span>
            </a>
          </div>

        </div>

      </div>

      {/* Floating Buttons */}
      <div className={`
        z-20 fixed flex-col right-0 top-1/2
        bg-[#f40010]
        py-9 pl-3 pr-2.5 rounded-tl-full rounded-bl-full
        md:block hidden
        `}>

        <a className='flex mb-3.5'
          href="https://wa.me/message/FDP5HUUJMOWCK1">
          <img src={wa} className='h-auto w-7' />
        </a>

        <a className='flex mb-3.5'
          href="https://instagram.com/annyeongacademy?igshid=MzRlODBiNWFlZA==">
          <img src={ig} className='h-7 w-auto' />
        </a>

        <a className='flex mb-3.5'
          href="https://www.tiktok.com/@annyeongacademy?_t=8g57GElGMEc&_r=1">
          <img src={tiktok} className='h-7 w-auto' />
        </a>

        <a className='flex' href="">
          <img src={yt} className='h-auto w-7' />
        </a>

      </div>

    </>
  )
}
